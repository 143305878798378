import {Outlet} from "react-router-dom";
import React from "react";
import {LoginTemplate} from "components/templates/LoginTemplate";

const LoginMerchantView = () => {
    return (
        <>
            <LoginTemplate.Root>
                <LoginTemplate.Aside>
                    <h2>Welcome, Merchant.</h2>
                </LoginTemplate.Aside>
                <LoginTemplate.Content>
                    <>
                        <div className="ui-welcome-page-account">
                            <div className="ui-welcome-page-account-container">
                                <Outlet />
                            </div>
                        </div>
                    </>
                </LoginTemplate.Content>
            </LoginTemplate.Root>
        </>
    );
};

export default LoginMerchantView;

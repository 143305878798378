import flattenDeep from "lodash/flattenDeep";
import uniqueId from "lodash/uniqueId";
import React, {Suspense} from "react";
import {Outlet, Route, Routes as ReactRoutes} from "react-router-dom";
import PublicTemplate from "components/templates/PublicTemplate";
import LoginAgentView from "portals/agent/components/templates/LoginAgentView";
import LoginMerchantView from "portals/merchant/components/templates/LoginMerchantView";
import PortalAgentTemplate from "portals/agent/components/templates/PortalAgentTemplate";
import PortalMerchantTemplate from "portals/merchant/components/templates/PortalMerchantTemplate";
import PrivateTemplate from "components/templates/PrivateTemplate";
import Spinner from "components/atoms/Spinner";

const generateFlattenRoutes = (routes) => {
  if (!routes) return [];
  return flattenDeep(
    routes.map(({ routes: subRoutes, ...rest }) => [
      rest,
      generateFlattenRoutes(subRoutes),
    ])
  );
};

export const renderRoutes = (appRoutes) => {

  return ({ isAuthorized, user, associatedRole }) => {
    const { routes } = isAuthorized ? appRoutes[associatedRole] : appRoutes.public;
    const subRoutes = generateFlattenRoutes(routes);

    return (
      <ReactRoutes>
        <Route
          key={uniqueId()}
          element={
            isAuthorized ? (
              <>
                <PrivateTemplate>
                  {associatedRole === "merchant" ? (
                    <PortalMerchantTemplate user={user} routes={routes} />
                  ) : (
                    <PortalAgentTemplate user={user} routes={routes} />
                  )}
                </PrivateTemplate>
              </>
            ) : (
              <>
                <PublicTemplate>
                  {associatedRole === "merchant" ? (
                    <LoginMerchantView />
                  ) : (
                    <LoginAgentView />
                  )}
                </PublicTemplate>
              </>
            )
          }
        >
          <Route
            element={
              <Suspense fallback={<Spinner />}>
                <Outlet />
              </Suspense>
            }
          >
            {subRoutes.map(({ component: Component, path, name }) => {
              return (
                Component &&
                path && <Route key={name} element={<Component />} path={path} />
              );
            })}
          </Route>
        </Route>
      </ReactRoutes>
    );
  };
};

export const endpoints = {
    merchant_list: "merchant_list",
    merchant_details: "merchant_details",
    merchant_details_underwriting_requests: "merchant_details/underwriting_requests",
    merchant_details_requests: "merchant_details/request",
    merchant_details_requests_notes: "merchant_details/request/notes",
    merchant_details_memos: "merchant_details/memos",
    merchant_details_related_dbas: "merchant_details/related_dbas",
    merchant_details_documents: "merchant_details/documents",
    merchant_details_documents_downloads: "merchant_details/documents/downloads",
    merchant_details_documents_types: "merchant_details/documents/documents_type",
    merchant_details_documents_history: "merchant_details/documents/documents_upload_history",
    merchant_details_documents_pre_nimbus_asset: "merchant_details/documents/pre_nimbus_asset",
    merchant_details_edocuments: "merchant_details/e-documents",
    asset_upload: "asset_upload",
    portal_documents_downloads: "merchant_details/documents/portal_downloads",
    portal_documents: "portal_documents",
    profile: "settings",
    profileDetails: "details",
    profilePassword: "settings/password",
    residuals: "residuals",
    residuals_portfolio: "portfolio",
    residuals_analysis: "residuals/analysis",
    documents: "assets",
    application: "merchant_application",
    application_submit: "merchant_application_submit",
    impersonate: "merchant_application/impersonate",
    portal_documents: "portal_documents"
}
import {useContext, useEffect, useState} from "react";
import {UserContext} from "contexts/UserContext";
import axios from "axios";
import useInterceptImpersonate from "portals/agent/hooks/useInterceptImpersonate";
import {AppContext} from "contexts/AppContext";
import {getEnv} from "components/utils/getEnv";

export const mockAPI = () => {
  return axios.create({
    baseURL: "/json",
  });
};

const setEnv = () => {
  const env = getEnv();

  let baseUrl =
    "https://9hlblvtgsh.execute-api.us-west-2.amazonaws.com/production/v1/";
  // Production Environment (default vars)
  if (env === "development") {
    // Development Environment
    baseUrl =
      "https://senjvqrqad.execute-api.us-west-2.amazonaws.com/development/v1/";
  } else if (env === "staging") {
    // Staging Environment
    baseUrl =
      "https://cbyn5ogpvb.execute-api.us-west-2.amazonaws.com/staging/v1/";
  }
  return {
    url: baseUrl,
  };
};

const useAPI = () => {
  const { validateRequestedUrl } = useInterceptImpersonate();

  const [jwt, setJwt] = useState(null);
  const [impersonateEmail, setImpersonateEmail] = useState(null);
  const [userInfo] = useContext(UserContext);
  const [envVars, setEnvVars] = useState({ url: null });

  const {
    appContextValues: { portalRole },
  } = useContext(AppContext);

  useEffect(() => {
    if (envVars.url === null) {
      const { url } = setEnv();
      setEnvVars({
        url,
      });
    }
  }, []);

  useEffect(() => {
    if (userInfo?.token) {
      setJwt(userInfo.token);
    }
  }, [userInfo?.token]);

  useEffect(() => {
    if (
      userInfo?.["_impersonate-email"] &&
      userInfo?.["_impersonate-email"] !== userInfo?.email
    ) {
      setImpersonateEmail(userInfo?.["_impersonate-email"]);
    }
  }, [userInfo?.["_impersonate-email"]]);

  const AxiosInstance = axios.create({ baseURL: envVars.url });

  AxiosInstance.interceptors.request.use(
    async (config) => {
      config.headers["Authorization"] = `Bearer ${jwt}`;

      // Conditionally includes the impersonate email on the config.url as a query parameter.
      if (userInfo?.is_portal_admin && impersonateEmail) {
        const updatedEndpoint = validateRequestedUrl(
          config.url,
          impersonateEmail
        );
        config = {
          ...config,
          url: updatedEndpoint,
        };
      }

      // Includes portalRole on the config.url as a query parameter.
      const configUrl = config.url;
      config.url = `${configUrl}${
        configUrl.includes("?") ? "&" : "?"
      }portal_role=${portalRole || "unset"}`;

      return config;
    },
    async (error) => {
      await Promise.reject(error);
    }
  );

  AxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.message === "The incoming token has expired"
      ) {
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 300);
      }
      return Promise.reject(error);
    }
  );

  return {
    AxiosInstance,
    hasToken: !!jwt,
  };
};

export default useAPI;

import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import Sidebar from "components/organisms/Sidebar";
import PageHeader from "components/organisms/PageHeader";
import ContentContainer from "components/organisms/ContentContainer";
import {PortalTemplate} from "components/templates/PortalTemplate";
import usePortalTemplate from "hooks/usePortalTemplate";
import 'styles/merchant.css';
import {useProfile} from "portals/agent/services/profile";

const PortalAgentTemplate = ({ user, routes }) => {
  const { currentRoute, showMobileMenu, hideMobileMenu } =
    usePortalTemplate(routes);

  // @TODO: fetching data for testing purposes - remove after validating ticket NM-889.
  const { data } = useProfile();
  useEffect(() => {
    if (data) console.log("useProfile fetch --- testing user role flag", !!data);
  }, [data]);
  // ---

  return (
    <>
      <PortalTemplate.Root>
        <PortalTemplate.Aside>
          <Sidebar title={currentRoute?.title} />
        </PortalTemplate.Aside>
        <PortalTemplate.Content>
          <div className="templateHeader">
            <PageHeader
              user={user}
              title={currentRoute?.title}
              path={currentRoute?.path}
            />
          </div>
          <section className={`templateContent`}>
            <ContentContainer>
              <Outlet />
            </ContentContainer>
          </section>
        </PortalTemplate.Content>
        <PortalTemplate.Footer>
          {showMobileMenu && (
            <span className="ui-backdrop" onClick={hideMobileMenu} />
          )}
        </PortalTemplate.Footer>
      </PortalTemplate.Root>
    </>
  );
};

export default React.memo(PortalAgentTemplate);

import {styled} from "@mui/material/styles";
import {Breadcrumbs} from "@mui/material";

const StyledBreadcrumb = styled(Breadcrumbs)(() => ({
  ".MuiBreadcrumbs-li a": {
    color: "rgba(0, 0, 0, 0.4)",
    fontSize: "14px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  ".MuiBreadcrumbs-li:last-of-type a": {
    color: "#000",
  },
  ".MuiBreadcrumbs-li:first-of-type a": {
    color: "rgba(0, 0, 0, 0.4)",
  },
  ".MuiBreadcrumbs-separator": {
    color: "rgba(0, 0, 0, 0.4)",
    fontSize: "14px",
  },
}));

export default StyledBreadcrumb;

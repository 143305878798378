export const PORTAL_CONST = Object.freeze({
    AGENT_ROLE: "agent",
    MERCHANT_ROLE: "merchant",
    LOCATION_HOST_ROLE_AGENT_LOCAL: "localhost:3000",
    LOCATION_HOST_ROLE_AGENT_DEV: "nma-agent-portal-development.metaltoad-sites.com",
    LOCATION_HOST_ROLE_AGENT_STG: "nma-agent-portal-staging.metaltoad-sites.com",
    LOCATION_HOST_ROLE_AGENT_PRD: "portal.nationalmerchants.com",
    LOCATION_HOST_ROLE_MERCHANT_LOCAL: "localhost:3001",
    LOCATION_HOST_ROLE_MERCHANT_DEV: "nma-merchant-portal-development.metaltoad-sites.com",
    LOCATION_HOST_ROLE_MERCHANT_STG: "nma-merchant-portal-staging.metaltoad-sites.com",
    LOCATION_HOST_ROLE_MERCHANT_PRD: "portal-merchant.nationalmerchants.com",
    LOCAL_STORAGE_ASSOCIATED_ROLE: "AssociatedPortalRole"
})

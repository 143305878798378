import NmaButton from "components/molecules/NmaButton";
import uniqueId from "lodash/uniqueId";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Menu} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Outlet} from "react-router-dom";
import RequestAccessDialog from "portals/agent/components/organisms/Dialogs/RequestAccessDialog";
import BecomeAnNMAProAgentDialog from "portals/agent/components/organisms/Dialogs/BecomeAnNMAProAgentDialog";
import React, {useState} from "react";
import {LoginTemplate} from "components/templates/LoginTemplate";

const LoginAgentView = () => {
  const [showRequestAccessDialog, setShowRequestAccessDialog] = useState(false);
  const [showBecomeAnNMAProAgentDialog, setShowBecomeAnNMAProAgentDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuLinks = [
    {
      id: "industries",
      label: "Industries",
      options: [
        {
          label: "Lorem ipsum",
        },
      ],
    },
    {
      id: "partnerships",
      label: "Partnerships",
      options: [
        {
          label: "Lorem ipsum",
        },
      ],
    },
    {
      id: "careers",
      label: "Careers",
    },
    {
      id: "about-us",
      label: "About Us",
      options: [
        {
          label: "Lorem ipsum",
        },
      ],
    },
    {
      id: "contact-us",
      label: "Contact Us",
    },
  ];

  return (
    <>
      <LoginTemplate.Root>
        <LoginTemplate.Aside>
          <h2>Become an NMA ProAgent</h2>
          <p>
            NMA offers a best-in-class Partner Program with unmatched benefits
            for you and your merchants.
          </p>
          <NmaButton
            onClick={() => {
              window.location.href =
                "https://www.nationalmerchants.com/get-started/";
            }}
            style="filled"
            fullWidth={true}
          >
            become a partner
          </NmaButton>
        </LoginTemplate.Aside>
        <LoginTemplate.Content>
          <>
            {/*Disable navigation menu for MVP*/}
            {1 > 2 && (
              <nav>
                <ul>
                  {menuLinks?.map((menuLink) => {
                    return (
                      <li key={uniqueId()}>
                        <div>
                          <Button
                            id={menuLink.id}
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                          >
                            {menuLink.label}{" "}
                            {menuLink.options && <KeyboardArrowDownIcon />}
                          </Button>
                          {menuLink.options && (
                            <Menu
                              id={`${menuLink.id}_menu`}
                              anchorEl={anchorEl}
                              open={open && anchorEl.id === menuLink.id}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": menuLink.id,
                              }}
                            >
                              {menuLink.options?.map((option) => {
                                return (
                                  <MenuItem
                                    key={uniqueId()}
                                    onClick={handleClose}
                                  >
                                    {option.label}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            )}
            <div className="ui-welcome-page-account">
              <div className="ui-welcome-page-account-container">
                <Outlet />
                <div className="ui-account-form-bottom-text">
                  <p>
                    Don’t have an account?{" "}
                    <button
                      onClick={() => {
                        window.location.href =
                          "https://www.nationalmerchants.com/get-started/";
                      }}
                    >
                      Request Access
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </>
        </LoginTemplate.Content>
      </LoginTemplate.Root>

      <RequestAccessDialog
        isOpen={showRequestAccessDialog}
        handleClose={() => setShowRequestAccessDialog(false)}
        handleConfirm={() => {
          // console.log("CONFIRM");
        }}
      />
      <BecomeAnNMAProAgentDialog
        isOpen={showBecomeAnNMAProAgentDialog}
        handleClose={() => setShowBecomeAnNMAProAgentDialog(false)}
        handleConfirm={() => {
          // console.log("CONFIRM");
        }}
      />
    </>
  );
};

export default LoginAgentView;

import {endpoints} from "services/endpoints";

const impersonateAbleEndpoints = [
  endpoints.merchant_list,
  endpoints.merchant_details,
  endpoints.merchant_details_underwriting_requests,
  endpoints.merchant_details_requests,
  endpoints.merchant_details_requests_notes,
  endpoints.merchant_details_memos,
  endpoints.merchant_details_related_dbas,
  endpoints.merchant_details_documents,
  endpoints.merchant_details_documents_downloads,
  endpoints.merchant_details_documents_history,
  endpoints.merchant_details_documents_pre_nimbus_asset,
  endpoints.merchant_details_edocuments,
  endpoints.application,
  endpoints.residuals,
  endpoints.residuals_analysis,
  endpoints.residuals_portfolio,
  endpoints.documents,
];
const useInterceptImpersonate = () => {
  const validateRequestedUrl = (url, email) => {
    let response = url;
    impersonateAbleEndpoints.some((endpoint) => {
      const endpointExtracted = url.split("?");
      if (endpointExtracted[0] === endpoint) {
        const params = new URLSearchParams(url);
        const queryParams = [];
        for (const [key, value] of params) {
          queryParams.push(key + "=" + value);
        }

        const impersonateParam = "impersonate_email=" + email;

        if (queryParams[0].includes("?")) {
          response = queryParams.join("&") + "&" + impersonateParam;
        } else {
          if (queryParams.length > 2) {
            const [first, second, ...others] = queryParams;
            response =
              first +
              "?" +
              second +
              "&" +
              others.join("&") +
              "&" +
              impersonateParam;
          } else {
            response =
              queryParams.length === 1
                ? queryParams[0] + "?" + impersonateParam
                : queryParams.join("?") + "&" + impersonateParam;
          }
        }
        return true;
      }
      return false;
    });
    return response;
  };

  return {
    validateRequestedUrl,
  };
};

export default useInterceptImpersonate;

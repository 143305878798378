import { useState } from "react";

const useNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const notify = (obj, timeout = 3500) => {
    return new Promise((resolveNotify, rejectNotify) => {
      const dateTime = new Date();

      if (notifications.length && notifications[0]?.dateTime === dateTime) {
        rejectNotify();
      } else {
        clean().then(() => {
          setNotifications((prev) => [
            {
              ...obj,
              dateTime: dateTime,
              timeout: timeout
            },
          ]);
          const newTimeoutId = setTimeout(() => {
            hide().then(() => {
              clean().then(() => {
                resolveNotify();
              });
            });
          }, timeout);
          setTimeoutId(newTimeoutId);
        });
      }
    });
  };
  const hide = async () => {
    clearTimeout(timeoutId);
    return new Promise((resolve, reject) => {
      setNotifications((prev) => [
        {
          ...prev[0],
          show: false,
        },
      ]);
      setTimeout(() => {
        resolve();
      }, 300);
    });
  };
  const clean = async () => {
    clearTimeout(timeoutId);
    return new Promise((resolve, reject) => {
      setNotifications([]);
      resolve();
    });
  };

  return {
    notifications,
    notify,
    clearNotifications: clean,
  };
};

export default useNotification;

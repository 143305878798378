import React, {lazy, Suspense, useContext} from "react";
import {AppContext} from "contexts/AppContext";
import NmaIcon from "components/atoms/NmaIcon";
import NmaButton from "components/molecules/NmaButton";

import {NavLink, useNavigate} from "react-router-dom";
import uniqueId from "lodash/uniqueId";
import Logo from "components/atoms/Logo";
import StyledBreadcrumb from "components/atoms/StyledBreadcrumb";
import Spinner from "components/atoms/Spinner";

const UserBadge = lazy(() => import("components/organisms/UserBadge"));
const ImpersonateDropdown = lazy(() =>
  import("portals/agent/components/organisms/ImpersonateDropdown")
);

const PageHeader = ({ user, title, path = "/" }) => {
  const navigate = useNavigate();
  const {
    appContextValues: { collapsed, isSm, portalRole },
    setAppContextValues,
  } = useContext(AppContext);

  const toggleSidebar = () => {
    setAppContextValues((prev) => {
      return {
        ...prev,
        collapsed: !prev.collapsed,
      };
    });
  };

  const toggleMobileMenu = () => {
    setAppContextValues((prev) => {
      return {
        ...prev,
        showMobileMenu: !prev.showMobileMenu,
      };
    });
  };

  const username =
    !!user?.name && !!user?.family_name
      ? `${user?.name} ${user?.family_name}`
      : null;

  return (
    <header
      className={`ui-page-header ${
        collapsed ? "ui-page-header--collapsed" : ""
      }`}
    >
      <div className="ui-page-header-navigation">
        {isSm ? (
          <NmaButton onClick={toggleMobileMenu}>
            <NmaIcon name={"menu"} />
          </NmaButton>
        ) : (
          <NmaButton
            onClick={() => {
              toggleSidebar();
            }}
            title={collapsed ? "Open side menu" : "Collapse side menu"}
          >
            <NmaIcon name="collapsible" isActive={collapsed} />
          </NmaButton>
        )}
        {!isSm && (
          <StyledBreadcrumb aria-label="breadcrumb">
            <NavLink
              key={uniqueId()}
              underline="hover"
              color="inherit"
              to={path}
            >
              {title}
            </NavLink>
          </StyledBreadcrumb>
        )}
      </div>
      {isSm && <Logo collapsed={true} />}
      <div className="ui-page-header-user-infos">
        {!isSm && (
          <>
            {user?.is_portal_admin === "1" && portalRole === "agent" && (
              <Suspense fallback={<Spinner size={"small"} />}>
                <ImpersonateDropdown />
              </Suspense>
            )}
            <Suspense fallback={<Spinner size={"small"} />}>
              <UserBadge
                username={username}
                id={user?.custom_sales_id}
                loading={!user?.name}
              />
            </Suspense>
          </>
        )}
        <NmaButton
          onClick={() => {
            navigate("/logout");
          }}
          title={"Logout"}
        >
          <NmaIcon name="logout" />
        </NmaButton>
      </div>
    </header>
  );
};

export default React.memo(PageHeader);

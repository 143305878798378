import React, {lazy, Suspense, useContext} from "react"
import Menu from "components/molecules/Menu";
import {AppContext} from "contexts/AppContext";
import Spinner from "components/atoms/Spinner";

const AgentMenu = lazy(() => import("portals/agent/components/organisms/Menu"));
const MerchantMenu = lazy(() =>
  import("portals/merchant/components/organisms/Menu")
);
const Logo = lazy(() => import("components/atoms/Logo"));
const Sidebar = ({ title }) => {
  const {
    appContextValues: { collapsed, showMobileMenu, isSm, portalRole },
  } = useContext(AppContext);

  return (
    <div
      className={`ui-sidebar ${collapsed ? "ui-sidebar--collapsed" : ""} ${
        isSm ? "ui-sidebar-mobile" : ""
      } ${
        showMobileMenu ? "ui-sidebar-mobile--show" : "ui-sidebar-mobile--hide"
      }`}
    >
      {!isSm && (
        <Suspense fallback={<Spinner size={"small"} />}>
          <Logo collapsed={collapsed} />
        </Suspense>
      )}
      <Menu>
        {portalRole === "merchant" ? (
          <Suspense fallback={<Spinner />}>
            <MerchantMenu current={title} />
          </Suspense>
        ) : (
          <Suspense fallback={<Spinner />}>
            <AgentMenu current={title} />
          </Suspense>
        )}
      </Menu>
    </div>
  );
};

export default React.memo(Sidebar);

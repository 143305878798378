import React, {lazy, Suspense} from "react";
import {Outlet} from "react-router-dom";
import Sidebar from "components/organisms/Sidebar";
import PageHeader from "components/organisms/PageHeader";
import ContentContainer from "components/organisms/ContentContainer";
import Grid from "components/atoms/Grid";
import {PortalTemplate} from "components/templates/PortalTemplate";
import Spinner from "components/atoms/Spinner";
import usePortalTemplate from "hooks/usePortalTemplate";

const ImpersonateDropdown = lazy(() =>
  import("portals/agent/components/organisms/ImpersonateDropdown")
);

const PortalAgentTemplate = ({ user, routes }) => {
  const {
    currentRoute,
    location,
    showMobileMenu,
    isSm,
    hideMobileMenu,
  } = usePortalTemplate(routes);

  return (
    <>
      <PortalTemplate.Root>
        <PortalTemplate.Aside>
          <Sidebar title={currentRoute?.title} />
        </PortalTemplate.Aside>
        <PortalTemplate.Content>
          <div className="templateHeader">
            <PageHeader
              user={user}
              title={currentRoute?.title}
              path={currentRoute?.path}
            />
          </div>
          <section
            className={`templateContent ${
              location?.pathname?.includes("origination/application")
                ? "is_origination_application"
                : ""
            }`}
          >
            <ContentContainer>
              {user?.is_portal_admin === "1" && isSm && (
                <Grid>
                  <Suspense fallback={<Spinner size={"small"} />}>
                    <ImpersonateDropdown
                      selectedEmail={user?.email}
                      isMobile={true}
                    />
                  </Suspense>
                </Grid>
              )}
              <Outlet />
            </ContentContainer>
          </section>
        </PortalTemplate.Content>
        <PortalTemplate.Footer>
          {showMobileMenu && (
            <span className="ui-backdrop" onClick={hideMobileMenu} />
          )}
        </PortalTemplate.Footer>
      </PortalTemplate.Root>
    </>
  );
};

export default React.memo(PortalAgentTemplate);

import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import 'styles/index.css';
import {BrowserRouter} from 'react-router-dom';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-T62MVNFH'
}

TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </React.StrictMode>
);

import {renderRoutes} from "routes/generate-routes";
import {lazy} from "react";
// Layouts
import PublicTemplate from "components/templates/PublicTemplate";
import PortalAgentTemplate from "portals/agent/components/templates/PortalAgentTemplate";
// Pages
import RedirectToDashboard from "components/pages/Redirect/ToDashboard";
import RedirectToLogin from "components/pages/Redirect/ToLogin";
import RedirectOnLogout from "components/pages/Redirect/OnLogout";
import PortalMerchantTemplate from "portals/merchant/components/templates/PortalMerchantTemplate";
import RedirectToHome from "components/pages/Redirect/ToHome";

const MerchantHome = lazy(() =>
    import("portals/merchant/components/pages/Home")
);
const MPA = lazy(() =>
    import("portals/merchant/components/pages/MPA")
);
const DownloadStatements = lazy(() =>
    import("portals/merchant/components/pages/DownloadStatements")
);
const Status = lazy(() =>
    import("portals/merchant/components/pages/Status")
);
const Dashboard = lazy(() =>
  import("portals/agent/components/pages/Dashboard")
);
const Merchants = lazy(() =>
  import("portals/agent/components/pages/Merchants")
);
const MerchantDetails = lazy(() =>
  import("portals/agent/components/pages/Merchants/Details")
);
const Residuals = lazy(() =>
  import("portals/agent/components/pages/Residuals")
);
const ResidualsDetails = lazy(() =>
  import("portals/agent/components/pages/Residuals/Details")
);
const Origination = lazy(() =>
  import("portals/agent/components/pages/Origination")
);
const Documents = lazy(() =>
  import("portals/agent/components/pages/Documents")
);
const Profile = lazy(() => import("portals/agent/components/pages/Profile"));
const Application = lazy(() =>
  import("portals/agent/components/pages/Origination/Application")
);
const ApplicationV2 = lazy(() =>
    import("portals/agent/components/pages/Origination/ApplicationV2")
);
const ResidualsCalculatorAdmin = lazy(() =>
  import("portals/agent/components/pages/Residuals/ResidualsCalculatorAdmin")
);
const Examples = lazy(() => import("components/pages/Examples"));
const Login = lazy(() => import("components/pages/Account/Login"));
const ForgotPassword = lazy(() =>
  import("components/pages/Account/ForgotPassword")
);
const Social = lazy(() => import("portals/agent/components/pages/Social"));
const Logout = lazy(() => import("components/pages/Account/Logout"));

export const PrivateRoutes = {
  Merchant: [
    {
      name: "home",
      title: "Home",
      component: MerchantHome,
      path: "/",
    },
    {
      name: "MPA",
      title: "MPA",
      component: MPA,
      path: "/mpa",
    },
    {
      name: "Download Statements",
      title: "Download Statements",
      component: DownloadStatements,
      path: "/download-statements",
    },
    {
      name: "dashboard",
      title: "Dashboard",
      component: Dashboard,
      path: "/dashboard",
    },
    {
      name: "status",
      title: "Status",
      component: Status,
      path: "/status",
    },
    {
      name: "onLogout",
      title: "onLogout",
      component: RedirectOnLogout,
      path: "/onlogout",
    },
    {
      name: "notfound",
      title: "404",
      component: RedirectToHome,
      path: "*",
    },
    {
      name: "logout",
      title: "Logout",
      component: Logout,
      path: "/logout",
    },
  ],
  Agent: [
    {
      name: "home",
      title: "Home page",
      component: RedirectToDashboard,
      path: "/",
    },
    {
      name: "dashboard",
      title: "Dashboard",
      component: Dashboard,
      path: "/dashboard",
    },
    {
      name: "merchants",
      title: "Status",
      hasSiderLink: true,
      routes: [
        {
          name: "merchants",
          title: "Status",
          hasSiderLink: true,
          component: Merchants,
          path: "/merchants",
        },
        {
          name: "merchants-details",
          title: "Merchant - Details",
          hasSiderLink: true,
          component: MerchantDetails,
          path: "/merchants/details/:id",
        },
      ],
    },
    {
      name: "documents",
      title: "Documents",
      component: Documents,
      path: "/documents",
    },
    {
      name: "profile",
      title: "Profile",
      component: Profile,
      path: "/profile",
    },
    {
      name: "residuals",
      title: "Residuals",
      hasSiderLink: true,
      routes: [
        {
          name: "residuals",
          title: "Residuals",
          hasSiderLink: true,
          component: Residuals,
          path: "/residuals",
        },
        {
          name: "residuals-calculator-admin",
          title: "Residuals Calculator Admin",
          hasSiderLink: true,
          component: ResidualsCalculatorAdmin,
          path: "/residuals/calculator",
        },
        {
          name: "residuals-details",
          title: "Residuals - Details",
          hasSiderLink: true,
          component: ResidualsDetails,
          path: "/residuals/details/:mid_number/:period_end_date",
        },
      ],
    },
    {
      name: "origination",
      title: "Origination",
      hasSiderLink: true,
      routes: [
        {
          name: "origination",
          title: "Origination",
          component: Origination,
          path: "/origination",
        },
        {
          name: "origination-application",
          title: "Application",
          component: Application,
          path: "/origination/application/:id?",
        },
        {
          name: "origination-applicationV2",
          title: "ApplicationV2",
          component: ApplicationV2,
          path: "/origination/applicationV2/:id?",
        },
      ],
    },
    {
      name: "examples",
      title: "Examples",
      component: Examples,
      path: "/examples",
    },
    {
      name: "social",
      title: "Social",
      component: Social,
      path: "/social",
    },
    {
      name: "onLogout",
      title: "onLogout",
      component: RedirectOnLogout,
      path: "/onlogout",
    },
    {
      name: "notfound",
      title: "404",
      component: RedirectToDashboard,
      path: "*",
    },
    {
      name: "logout",
      title: "Logout",
      component: Logout,
      path: "/logout",
    },
  ],
};

export const Routes = {
  public: {
    layout: PublicTemplate,
    routes: [
      {
        name: "login",
        title: "Login page",
        component: Login,
        path: "/account/login",
      },
      {
        name: "notfound",
        title: "404",
        component: RedirectToLogin,
        path: "*",
      },
      {
        name: "forgotpassword",
        title: "Forgot password",
        component: ForgotPassword,
        path: "/account/forgot-password",
      },
    ],
  },
  merchant: {
    layout: PortalMerchantTemplate,
    routes: PrivateRoutes.Merchant,
  },
  agent: {
    layout: PortalAgentTemplate,
    routes: PrivateRoutes.Agent,
  },
};

export const CustomRoutes = renderRoutes(Routes);

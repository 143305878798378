const Spinner = ({ centered = true, size = "default" }) => (
  <span
    className={`ui-spinner ${centered ? "ui-spinner--centered" : ""} ${
      size === "small" ? "ui-spinner--small" : ""
    }`}
    data-testid="spinner"
  />
);

export default Spinner;

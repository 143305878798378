import useAPI from "api";
import {endpoints} from "services/endpoints";
import {useMutation, useQuery} from "react-query";
import {RQCommonOptions} from "components/utils/collections/RQCommonOptions";
import {useContext, useState} from "react";
import {UserContext} from "contexts/UserContext";
import {NotificationContext} from "contexts/NotificationContext";

export const useProfile = () => {
  const { AxiosInstance, hasToken } = useAPI();

  const [userInfo] = useContext(UserContext);

  const { custom_staff_id: agent_id } = userInfo;

  async function fetchData() {
    return await AxiosInstance.get(`${endpoints.profile}?user_id=${agent_id}`);
  }

  const { isLoading, error, data, isSuccess, refetch } = useQuery(
    ["profileEndpoint", agent_id],
    fetchData,
    {
      ...RQCommonOptions,
      enabled: hasToken && !!agent_id,
    }
  );

  return {
    isLoading,
    error,
    data,
    isSuccess,
    refetch,
  };
};

export const useProfileMutation = () => {
  const { AxiosInstance, hasToken } = useAPI();
  const [userInfo] = useContext(UserContext);
  const [, notify, clearNotifications] = useContext(NotificationContext);
  const { custom_staff_id: agent_id } = userInfo;

  const [isMutating, setIsMutating] = useState(false);

  const mutation = useMutation({
    mutationFn: (formData) => {
      if (hasToken) {
        return AxiosInstance.post(endpoints.profile, {
          ...formData,
          user_id: agent_id,
        });
      } else {
        notify({
          show: true,
          title: "Error",
          message: "Unauthorized",
          severity: "error",
        });
      }
    },
    onMutate: () => {
      setIsMutating(true);
      clearNotifications();
    },
    onError: (error) => {
      notify({
        show: true,
        title: "Error",
        message: "Unable to perform the operation (" + error.message + ")",
        severity: "error",
      });
    },
    onSettled: () => {
      setIsMutating(false);
    },
  });

  return {
    mutation,
    isMutating,
  };
};

export const useProfileDetailsMutation = () => {
  const { AxiosInstance, hasToken } = useAPI();
  const [, notify, clearNotifications] = useContext(NotificationContext);

  const [isMutating, setIsMutating] = useState(false);

  const mutation = useMutation({
    mutationFn: (formData) => {
      if (hasToken) {
        return AxiosInstance.post(endpoints.profileDetails, {
          phone: formData,
        });
      } else {
        notify({
          show: true,
          title: "Error",
          message: "Unauthorized",
          severity: "error",
        });
      }
    },
    onMutate: () => {
      setIsMutating(true);
      clearNotifications();
    },
    onError: (error) => {
      notify({
        show: true,
        title: "Error",
        message: "Unable to perform the operation (" + error.message + ")",
        severity: "error",
      });
    },
    onSettled: () => {
      setIsMutating(false);
    },
  });

  return {
    mutation,
    isMutating,
  };
};

export const useProfilePasswordMutation = () => {
  const { AxiosInstance, hasToken } = useAPI();
  const [, notify, clearNotifications] = useContext(NotificationContext);

  const [isMutating, setIsMutating] = useState(false);

  const mutation = useMutation({
    mutationFn: ([old, password]) => {
      if (hasToken) {
        return AxiosInstance.post(endpoints.profilePassword, {
          old_password: old,
          new_password: password,
        });
      } else {
        notify({
          show: true,
          title: "Error",
          message: "Unauthorized",
          severity: "error",
        });
      }
    },
    onMutate: () => {
      setIsMutating(true);
      clearNotifications();
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.error?.includes(
        "Incorrect username or password"
      )
        ? "Failed to update your password. Please ensure that your current password is accurate."
        : "Unable to perform the operation (" + error.message + ")";

      notify({
        show: true,
        title: "Error",
        message: errorMessage,
        severity: "error",
      });
    },
    onSettled: () => {
      setIsMutating(false);
    },
  });

  return {
    mutation,
    isMutating,
  };
};

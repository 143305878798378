export const SvgIcons = {
  menu: {
    default: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
          fill="url(#paint0_linear_1988_15659)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1988_15659"
            x1="12"
            y1="6"
            x2="12"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
    active: null,
  },
  profile: {
    default: (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 2H10L8 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2ZM18 14H2V2H7.17L9.17 4H18V14ZM11.08 10.04L10.39 13L13 11.47L15.61 13L14.92 10.04L17.22 8.05L14.19 7.79L13 5L11.81 7.79L8.78 8.05L11.08 10.04Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 2H10L8 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2ZM18 14H2V2H7.17L9.17 4H18V14ZM11.08 10.04L10.39 13L13 11.47L15.61 13L14.92 10.04L17.22 8.05L14.19 7.79L13 5L11.81 7.79L8.78 8.05L11.08 10.04Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  social: {
    default: (
      <svg
        width="22"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M12 6.5C9.51 6.5 8 8.52 8 11V17H16V11C16 8.52 14.49 6.5 12 6.5Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          d="M18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17ZM12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="22"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M12 6.5C9.51 6.5 8 8.52 8 11V17H16V11C16 8.52 14.49 6.5 12 6.5Z"
          fill="url(#paint0_linear_2538_193)"
        />
        <path
          d="M18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17ZM12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22Z"
          fill="url(#paint1_linear_2538_193)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2538_193"
            x1="12"
            y1="6.5"
            x2="12"
            y2="17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2538_193"
            x1="12"
            y1="2.5"
            x2="12"
            y2="22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  documents: {
    default: (
      <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 0H2C0.9 0 0 0.9 0 2V16H2V2H14V0ZM13 4H6C4.9 4 4.01 4.9 4.01 6L4 20C4 21.1 4.89 22 5.99 22H17C18.1 22 19 21.1 19 20V10L13 4ZM17 20H6V6H12V11H17V20Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 0H2C0.9 0 0 0.9 0 2V16H2V2H14V0ZM13 4H6C4.9 4 4.01 4.9 4.01 6L4 20C4 21.1 4.89 22 5.99 22H17C18.1 22 19 21.1 19 20V10L13 4ZM17 20H6V6H12V11H17V20Z"
          fill="url(#paint0_linear_1231_33181)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1231_33181"
            x1="9.5"
            y1="0"
            x2="9.5"
            y2="22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  status: {
    default: (
      <svg
        width="19"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.88 16.47C19.32 15.77 19.58 14.96 19.58 14.08C19.58 11.59 17.57 9.58 15.08 9.58C12.59 9.58 10.58 11.59 10.58 14.08C10.58 16.57 12.59 18.58 15.07 18.58C15.95 18.58 16.77 18.32 17.46 17.88L20.58 21L22 19.58L18.88 16.47ZM15.08 16.58C13.7 16.58 12.58 15.46 12.58 14.08C12.58 12.7 13.7 11.58 15.08 11.58C16.46 11.58 17.58 12.7 17.58 14.08C17.58 15.46 16.46 16.58 15.08 16.58ZM14.72 8.08C13.98 8.1 13.27 8.26 12.62 8.53L12.07 7.7L8.27 13.88L5.26 10.36L1.63 16.17L0 15L5 7L8 10.5L12 4L14.72 8.08ZM17.31 8.58C16.67 8.3 15.98 8.13 15.26 8.09L20.38 0L22 1.18L17.31 8.58Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="19"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.88 16.47C19.32 15.77 19.58 14.96 19.58 14.08C19.58 11.59 17.57 9.58 15.08 9.58C12.59 9.58 10.58 11.59 10.58 14.08C10.58 16.57 12.59 18.58 15.07 18.58C15.95 18.58 16.77 18.32 17.46 17.88L20.58 21L22 19.58L18.88 16.47ZM15.08 16.58C13.7 16.58 12.58 15.46 12.58 14.08C12.58 12.7 13.7 11.58 15.08 11.58C16.46 11.58 17.58 12.7 17.58 14.08C17.58 15.46 16.46 16.58 15.08 16.58ZM14.72 8.08C13.98 8.1 13.27 8.26 12.62 8.53L12.07 7.7L8.27 13.88L5.26 10.36L1.63 16.17L0 15L5 7L8 10.5L12 4L14.72 8.08ZM17.31 8.58C16.67 8.3 15.98 8.13 15.26 8.09L20.38 0L22 1.18L17.31 8.58Z"
          fill="url(#paint0_linear_1231_19074)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1231_19074"
            x1="11"
            y1="0"
            x2="11"
            y2="21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  dashboard: {
    default: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3L2 12H5V20H11V14H13V20H19V12H22L12 3ZM17 18H15V12H9V18H7V10.19L12 5.69L17 10.19V18Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          opacity="0.3"
          d="M7 10.19V18H9V12H15V18H17V10.19L12 5.69L7 10.19Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3L2 12H5V20H11V14H13V20H19V12H22L12 3ZM17 18H15V12H9V18H7V10.19L12 5.69L17 10.19V18Z"
          fill="url(#paint0_linear_191_10770)"
        />
        <path
          opacity="0.3"
          d="M7 10.19V18H9V12H15V18H17V10.19L12 5.69L7 10.19Z"
          fill="url(#paint1_linear_191_10770)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_191_10770"
            x1="12"
            y1="3"
            x2="12"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_191_10770"
            x1="12"
            y1="5.69"
            x2="12"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  merchants: {
    default: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M6.44009 9.86L7.02009 5H5.05009L4.04009 9.36C3.94009 9.78 4.03009 10.2 4.29009 10.53C4.43009 10.71 4.73009 11 5.23009 11C5.84009 11 6.36009 10.51 6.44009 9.86Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          opacity="0.3"
          d="M9.70989 11C10.4499 11 10.9999 10.41 10.9999 9.69V5H9.03989L8.48989 9.52C8.43989 9.91 8.55989 10.3 8.81989 10.59C9.04989 10.85 9.36989 11 9.70989 11Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          opacity="0.3"
          d="M14.22 11C14.63 11 14.94 10.85 15.18 10.59C15.43 10.3 15.55 9.91 15.51 9.52L14.96 5H13V9.69C13 10.41 13.55 11 14.22 11Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          opacity="0.3"
          d="M18.91 4.98999L16.98 4.99999L17.56 9.85999C17.64 10.51 18.16 11 18.77 11C19.26 11 19.57 10.71 19.7 10.53C19.96 10.2 20.05 9.76999 19.95 9.35999L18.91 4.98999Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          d="M21.9 8.89L20.85 4.52C20.63 3.62 19.85 3 18.94 3H5.05001C4.15001 3 3.36001 3.63 3.15001 4.52L2.10001 8.89C1.86001 9.91 2.08001 10.95 2.72001 11.77C2.80001 11.88 2.91001 11.96 3.00001 12.06V19C3.00001 20.1 3.90001 21 5.00001 21H19C20.1 21 21 20.1 21 19V12.06C21.09 11.97 21.2 11.88 21.28 11.78C21.92 10.96 22.15 9.91 21.9 8.89ZM13 5H14.96L15.5 9.52C15.55 9.91 15.43 10.3 15.17 10.59C14.95 10.85 14.63 11 14.22 11C13.55 11 13 10.41 13 9.69V5ZM8.49001 9.52L9.04001 5H11V9.69C11 10.41 10.45 11 9.71001 11C9.37001 11 9.06001 10.85 8.82001 10.59C8.57001 10.3 8.45001 9.91 8.49001 9.52ZM4.29001 10.53C4.03001 10.2 3.94001 9.77 4.04001 9.36L5.05001 5H7.02001L6.44001 9.86C6.36001 10.51 5.84001 11 5.23001 11C4.73001 11 4.43001 10.71 4.29001 10.53ZM19 19H5.00001V12.97C5.08001 12.98 5.15001 13 5.23001 13C6.10001 13 6.89001 12.64 7.47001 12.05C8.07001 12.65 8.87001 13 9.78001 13C10.65 13 11.43 12.64 12.01 12.07C12.6 12.64 13.4 13 14.3 13C15.14 13 15.94 12.65 16.54 12.05C17.12 12.64 17.91 13 18.78 13C18.86 13 18.93 12.98 19.01 12.97V19H19ZM19.71 10.53C19.57 10.71 19.27 11 18.77 11C18.16 11 17.63 10.51 17.56 9.86L16.98 5L18.91 4.99L19.96 9.36C20.06 9.78 19.97 10.21 19.71 10.53Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M6.44009 9.86L7.02009 5H5.05009L4.04009 9.36C3.94009 9.78 4.03009 10.2 4.29009 10.53C4.43009 10.71 4.73009 11 5.23009 11C5.84009 11 6.36009 10.51 6.44009 9.86Z"
          fill="url(#paint0_linear_243_2339)"
        />
        <path
          opacity="0.3"
          d="M9.70989 11C10.4499 11 10.9999 10.41 10.9999 9.69V5H9.03989L8.48989 9.52C8.43989 9.91 8.55989 10.3 8.81989 10.59C9.04989 10.85 9.36989 11 9.70989 11Z"
          fill="url(#paint1_linear_243_2339)"
        />
        <path
          opacity="0.3"
          d="M14.22 11C14.63 11 14.94 10.85 15.18 10.59C15.43 10.3 15.55 9.91 15.51 9.52L14.96 5H13V9.69C13 10.41 13.55 11 14.22 11Z"
          fill="url(#paint2_linear_243_2339)"
        />
        <path
          opacity="0.3"
          d="M18.91 4.99001L16.98 5.00001L17.56 9.86001C17.64 10.51 18.16 11 18.77 11C19.26 11 19.57 10.71 19.7 10.53C19.96 10.2 20.05 9.77001 19.95 9.36001L18.91 4.99001Z"
          fill="url(#paint3_linear_243_2339)"
        />
        <path
          d="M21.9 8.89L20.85 4.52C20.63 3.62 19.85 3 18.94 3H5.05001C4.15001 3 3.36001 3.63 3.15001 4.52L2.10001 8.89C1.86001 9.91 2.08001 10.95 2.72001 11.77C2.80001 11.88 2.91001 11.96 3.00001 12.06V19C3.00001 20.1 3.90001 21 5.00001 21H19C20.1 21 21 20.1 21 19V12.06C21.09 11.97 21.2 11.88 21.28 11.78C21.92 10.96 22.15 9.91 21.9 8.89ZM13 5H14.96L15.5 9.52C15.55 9.91 15.43 10.3 15.17 10.59C14.95 10.85 14.63 11 14.22 11C13.55 11 13 10.41 13 9.69V5ZM8.49001 9.52L9.04001 5H11V9.69C11 10.41 10.45 11 9.71001 11C9.37001 11 9.06001 10.85 8.82001 10.59C8.57001 10.3 8.45001 9.91 8.49001 9.52ZM4.29001 10.53C4.03001 10.2 3.94001 9.77 4.04001 9.36L5.05001 5H7.02001L6.44001 9.86C6.36001 10.51 5.84001 11 5.23001 11C4.73001 11 4.43001 10.71 4.29001 10.53ZM19 19H5.00001V12.97C5.08001 12.98 5.15001 13 5.23001 13C6.10001 13 6.89001 12.64 7.47001 12.05C8.07001 12.65 8.87001 13 9.78001 13C10.65 13 11.43 12.64 12.01 12.07C12.6 12.64 13.4 13 14.3 13C15.14 13 15.94 12.65 16.54 12.05C17.12 12.64 17.91 13 18.78 13C18.86 13 18.93 12.98 19.01 12.97V19H19ZM19.71 10.53C19.57 10.71 19.27 11 18.77 11C18.16 11 17.63 10.51 17.56 9.86L16.98 5L18.91 4.99L19.96 9.36C20.06 9.78 19.97 10.21 19.71 10.53Z"
          fill="url(#paint4_linear_243_2339)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_243_2339"
            x1="5.51017"
            y1="5"
            x2="5.51017"
            y2="11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_243_2339"
            x1="9.73932"
            y1="5"
            x2="9.73932"
            y2="11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_243_2339"
            x1="14.2588"
            y1="5"
            x2="14.2588"
            y2="11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_243_2339"
            x1="18.4849"
            y1="4.99001"
            x2="18.4849"
            y2="11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_243_2339"
            x1="12.0022"
            y1="3"
            x2="12.0022"
            y2="21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  applications: {
    default: (
      <svg
        width="19"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M12.06 10.02L5 17.08V18H5.92L12.98 10.94L12.06 10.02Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path d="M15 16L11 20H21V16H15Z" fill="black" fillOpacity="0.6" />
        <path
          d="M12.06 7.19L3 16.25V20H6.75L15.81 10.94L12.06 7.19ZM5.92 18H5V17.08L12.06 10.02L12.98 10.94L5.92 18Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M18.7099 8.04C19.0999 7.65 19.0999 7.02 18.7099 6.63L16.3699 4.29C16.1699 4.09 15.9199 4 15.6599 4C15.4099 4 15.1499 4.1 14.9599 4.29L13.1299 6.12L16.8799 9.87L18.7099 8.04Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="19"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M12.06 10.02L5 17.08V18H5.92L12.98 10.94L12.06 10.02Z"
          fill="url(#paint0_linear_247_14846)"
        />
        <path d="M15 16L11 20H21V16H15Z" fill="url(#paint1_linear_247_14846)" />
        <path
          d="M12.06 7.19L3 16.25V20H6.75L15.81 10.94L12.06 7.19ZM5.92 18H5V17.08L12.06 10.02L12.98 10.94L5.92 18Z"
          fill="url(#paint2_linear_247_14846)"
        />
        <path
          d="M18.7099 8.04C19.0999 7.65 19.0999 7.02 18.7099 6.63L16.3699 4.29C16.1699 4.09 15.9199 4 15.6599 4C15.4099 4 15.1499 4.1 14.9599 4.29L13.1299 6.12L16.8799 9.87L18.7099 8.04Z"
          fill="url(#paint3_linear_247_14846)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_247_14846"
            x1="8.99"
            y1="10.02"
            x2="8.99"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_247_14846"
            x1="16"
            y1="16"
            x2="16"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_247_14846"
            x1="9.405"
            y1="7.19"
            x2="9.405"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_247_14846"
            x1="16.0661"
            y1="4"
            x2="16.0661"
            y2="9.87"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  residuals: {
    default: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10.31 9.14C8.54 8.69 7.97 8.2 7.97 7.47C7.97 6.63 8.76 6.04 10.07 6.04C11.45 6.04 11.97 6.7 12.01 7.68H13.72C13.67 6.34 12.85 5.11 11.23 4.71V3H8.9V4.69C7.39 5.01 6.18 5.99 6.18 7.5C6.18 9.29 7.67 10.19 9.84 10.71C11.79 11.17 12.18 11.86 12.18 12.58C12.18 13.11 11.79 13.97 10.08 13.97C8.48 13.97 7.85 13.25 7.76 12.33H6.04C6.14 14.03 7.4 14.99 8.9 15.3V17H11.24V15.33C12.76 15.04 13.96 14.17 13.97 12.56C13.96 10.36 12.07 9.6 10.31 9.14Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10.31 9.14C8.54 8.69 7.97 8.2 7.97 7.47C7.97 6.63 8.76 6.04 10.07 6.04C11.45 6.04 11.97 6.7 12.01 7.68H13.72C13.67 6.34 12.85 5.11 11.23 4.71V3H8.9V4.69C7.39 5.01 6.18 5.99 6.18 7.5C6.18 9.29 7.67 10.19 9.84 10.71C11.79 11.17 12.18 11.86 12.18 12.58C12.18 13.11 11.79 13.97 10.08 13.97C8.48 13.97 7.85 13.25 7.76 12.33H6.04C6.14 14.03 7.4 14.99 8.9 15.3V17H11.24V15.33C12.76 15.04 13.96 14.17 13.97 12.56C13.96 10.36 12.07 9.6 10.31 9.14Z"
          fill="url(#paint0_linear_1231_36777)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1231_36777"
            x1="10"
            y1="0"
            x2="10"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  news: {
    default: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 3L20.33 4.67L18.67 3L17 4.67L15.33 3L13.67 4.67L12 3L10.33 4.67L8.67 3L7 4.67L5.33 3L3.67 4.67L2 3V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V3ZM11 19H4V13H11V19ZM20 19H13V17H20V19ZM20 15H13V13H20V15ZM20 11H4V8H20V11Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 3L20.33 4.67L18.67 3L17 4.67L15.33 3L13.67 4.67L12 3L10.33 4.67L8.67 3L7 4.67L5.33 3L3.67 4.67L2 3V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V3ZM11 19H4V13H11V19ZM20 19H13V17H20V19ZM20 15H13V13H20V15ZM20 11H4V8H20V11Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  notification: {
    default: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M12 6.5C9.51 6.5 8 8.52 8 11V17H16V11C16 8.52 14.49 6.5 12 6.5Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          d="M18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17ZM12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M12 6.5C9.51 6.5 8 8.52 8 11V17H16V11C16 8.52 14.49 6.5 12 6.5Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          d="M18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17ZM12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  feedback: {
    default: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M4 18L6 16H20V4H4V18Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
    active: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M4 18L6 16H20V4H4V18Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z"
          fill="black"
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  collapsible: {
    default: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 18H16V16H3V18ZM3 13H13V11H3V13ZM3 6V8H16V6H3ZM21 15.59L17.42 12L21 8.41L19.59 7L14.59 12L19.59 17L21 15.59Z"
          fill="url(#paint0_linear_231_9239)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_231_9239"
            x1="12"
            y1="6"
            x2="12"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
    active: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 6L8 6L8 8L21 8L21 6ZM21 11L11 11L11 13L21 13L21 11ZM21 18L21 16L8 16L8 18L21 18ZM3 8.41L6.58 12L3 15.59L4.41 17L9.41 12L4.41 7L3 8.41Z"
          fill="url(#paint0_linear_231_930)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_231_930"
            x1="12"
            y1="18"
            x2="12"
            y2="6"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  logout: {
    default: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2H9V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H9V16H2V2ZM18 9L14 5V8H6V10H14V13L18 9Z"
          fill="url(#paint0_linear_304_13062)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_304_13062"
            x1="9"
            y1="0"
            x2="9"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#534FA1" />
            <stop offset="0.854167" stopColor="#D32F89" />
            <stop offset="1" stopColor="#F175DA" />
          </linearGradient>
        </defs>
      </svg>
    ),
    active: null,
  },
  avatar: {
    default: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#BDBDBD" />
        <path
          d="M11.9999 12C13.8416 12 15.3333 10.5083 15.3333 8.66666C15.3333 6.82499 13.8416 5.33333 11.9999 5.33333C10.1583 5.33333 8.66659 6.82499 8.66659 8.66666C8.66659 10.5083 10.1583 12 11.9999 12ZM11.9999 13.6667C9.77492 13.6667 5.33325 14.7833 5.33325 17V18.6667H18.6666V17C18.6666 14.7833 14.2249 13.6667 11.9999 13.6667Z"
          fill="white"
        />
      </svg>
    ),
    active: null,
  },
};

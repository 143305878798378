import NmaIcon from "components/atoms/NmaIcon";
import Spinner from "components/atoms/Spinner";

const UserBadge = ({ username, id, loading = false }) => {
  return (
    <div className="ui-user-badge">
        <span className="ui-user-badge-icon">
            {loading ? <Spinner /> : null}
            <NmaIcon name="avatar" />
        </span>
        { username && (
            <p className="ui-user-badge-p">
                {username} {!!id && (<span>ID: {id}</span>)}
            </p>
        )}
    </div>
  );
};

export default UserBadge;

import {createContext, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {useMediaQuery} from "@mui/material";
import useAssociateRole from "hooks/useAssociateRole";

export const useAppContextValues = () => {
  const location = useLocation();
  const smallScreens = useMediaQuery("(max-width:1024px)");
  const mediumScreens = useMediaQuery(
    "(min-width:1025px) and (max-width: 1169px)"
  );
  const largeScreens = useMediaQuery("(min-width:1170px)");

  const { getRole, validateAssociatedRole } = useAssociateRole();

  const getAssociatedPortalRole = useMemo(() => {
    return getRole();
  }, []);

  const [appContextValues, setAppContextValues] = useState({
    location: null,
    collapsed: false,
    onHold: false,
    isSm: smallScreens,
    isMd: mediumScreens,
    isLg: largeScreens,
    showMobileMenu: false,
    onForbidden: false,
    onForbiddenOptions: {
      redirectTo: "/",
      timeInSeconds: "30",
    },
    portalRole: getAssociatedPortalRole,
  });

  const setAppContextIfChanged = (prevValue, newValue, field) => {
    return prevValue[field] === newValue[field]
      ? prevValue
      : { ...prevValue, [field]: newValue };
  };

  useEffect(() => {
    setAppContextValues((prev) =>
      setAppContextIfChanged(prev, location, "location")
    );
    window.dataLayer.push({
      event: "pageview",
    });

    validateAssociatedRole(appContextValues.portalRole);
  }, [location]);

  const memoizedProviderValue = useMemo(() => {
    return { appContextValues, setAppContextValues };
  }, [appContextValues]);

  return {
    memoizedProviderValue,
  };
};

export const AppContext = createContext(null);

const Grid = ({
  children,
  size = 1,
  gap = "20px",
  maxWidth = "100%",
  minWidth = "0px",
  fullHeight = false,
}) => {
  if (!children) return;
  const style = {
    minWidth: minWidth,
    maxWidth: maxWidth,
    width: `calc(${100 / size}% - ${gap})`,
    gap: gap,
    height: fullHeight ? "100%" : "unset"
  };
  return (
    <div className="ui-grid" style={style}>
      {children}
    </div>
  );
};

export default Grid;

import React, {lazy, Suspense, useEffect, useMemo, useState} from "react";
import {CustomRoutes} from "routes";
import {QueryClient, QueryClientProvider} from "react-query";
import {AppContext, useAppContextValues} from "contexts/AppContext";
import {AuthContext} from "contexts/AuthContext";
import {UserContext} from "contexts/UserContext";
import useAuth from "hooks/useAuth";
import Spinner from "components/atoms/Spinner";
import {NotificationContext} from "contexts/NotificationContext";
import useNotification from "hooks/useNotification";

const AlertMessages = lazy(() => import("components/organisms/AlertMessages"));
const ForbiddenDialog = lazy(() =>
  import("components/organisms/Dialogs/ForbiddenDialog")
);
const SpinnerContainer = lazy(() =>
  import("components/organisms/SpinnerContainer")
);

const App = () => {
  const { memoizedProviderValue } = useAppContextValues();
  const queryClient = new QueryClient();
  const [isVerifyingSession, setIsVerifyingSession] = useState(true);

  const {
    isAuthenticated,
    setIsAuthenticated,
    userSession,
    userData,
    setUserData,
    setupUserInfo,
  } = useAuth();

  const { notifications, notify, clearNotifications } = useNotification();

  const memoizedAuthContextValues = useMemo(() => {
    return [isAuthenticated, setIsAuthenticated];
  }, [isAuthenticated]);

  const memoizedUserContextValues = useMemo(() => {
    return [userData, setUserData];
  }, [userData]);

  const memoizedNotificationContextValues = useMemo(() => {
    return [notifications, notify, clearNotifications];
  }, [notifications]);

  useEffect(() => {
    (async () => {
      try {
        const { currentUser } = await userSession(
          memoizedProviderValue.appContextValues.portalRole
        );
        setIsVerifyingSession(false);
        setIsAuthenticated(true);
        await setupUserInfo(currentUser);
      } catch (e) {
        setIsVerifyingSession(false);
        setIsAuthenticated(false);
      }
    })();
  }, []);

  const displayNotifications = useMemo(() => {
    if (notifications.length) {
      return (
        <Suspense>
          <AlertMessages
            show={notifications[0]?.show}
            title={notifications[0]?.title}
            message={notifications[0]?.message}
            severity={notifications[0]?.severity}
            timeout={notifications[0]?.timeout}
          />
        </Suspense>
      );
    }
    return null;
  }, [notifications[0]]);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={memoizedProviderValue}>
        {memoizedProviderValue.appContextValues.onHold && (
          <Suspense>
            <SpinnerContainer show={true} />
          </Suspense>
        )}
        {memoizedProviderValue.appContextValues.onForbidden && (
          <Suspense>
            <ForbiddenDialog
              open={memoizedProviderValue.appContextValues.onForbidden}
              options={
                memoizedProviderValue.appContextValues.onForbiddenOptions
              }
            />
          </Suspense>
        )}
        <AuthContext.Provider value={memoizedAuthContextValues}>
          <NotificationContext.Provider
            value={memoizedNotificationContextValues}
          >
            {displayNotifications}
            {isVerifyingSession && <Spinner />}
            {!isVerifyingSession && (
              <UserContext.Provider value={memoizedUserContextValues}>
                <CustomRoutes
                  isAuthorized={isAuthenticated}
                  user={userData}
                  associatedRole={
                    memoizedProviderValue.appContextValues.portalRole
                  }
                />
              </UserContext.Provider>
            )}
          </NotificationContext.Provider>
        </AuthContext.Provider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default App;

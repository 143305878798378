import {PortalRoot} from "components/templates/PortalTemplate/Portal.Root";
import {PortalAside} from "components/templates/PortalTemplate/Portal.Aside";
import {PortalContent} from "components/templates/PortalTemplate/Portal.Content";
import {PortalFooter} from "components/templates/PortalTemplate/Portal.Footer";

export const PortalTemplate = {
    Root: PortalRoot,
    Aside: PortalAside,
    Content: PortalContent,
    Footer: PortalFooter
}
import {useContext} from "react";
import {AppContext} from "contexts/AppContext";
import UserBadge from "components/organisms/UserBadge";
import {UserContext} from "contexts/UserContext";

const Menu = ({ children }) => {
  const {
    appContextValues: { collapsed, isSm }
  } = useContext(AppContext);
  const [userInfo] = useContext(UserContext);
  const { custom_sales_id, name } = userInfo;

  return (
    <nav className={`ui-menu ${collapsed ? "ui-menu--collapsed" : ""}`}>
      {children}
      {isSm && (
        <UserBadge username={name} id={custom_sales_id} loading={!name} />
      )}
    </nav>
  );
};

export default Menu;

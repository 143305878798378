import { useContext } from "react";
import { AppContext } from "contexts/AppContext";

const ContentContainer = ({ children }) => {
  const {
    appContextValues: { collapsed, location },
  } = useContext(AppContext);
  if (!children) return;

  const modifiedPathname = location?.pathname?.split("/").join("-")

  return (
    <div
      className={`ui-content-container ui-content-container-${modifiedPathname} ${
        collapsed ? "ui-content-container--collapsed" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default ContentContainer;

import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import {useState} from "react";
import Pool from "services/auth/UserPool";
import {PORTAL_CONST} from "components/utils/collections/PortalConstants";

const useAuth = () => {
  const [isChecking, setIsChecking] = useState(true);
  const [isSigning, setIsSigning] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState({});

  const userSession = async (associatedPortalRole) => {
    setIsChecking(true);
    return await new Promise((resolve, reject) => {
      const currentUser = Pool.getCurrentUser();
      if (!currentUser) {
        reject("User not found. Reject and redirect to login page.");
      } else {
        currentUser.getSession((err, session) => {
          if (err) {
            reject("Session error. Reject: " + err);
          } else {
            const userRoles = session.getIdToken().decodePayload()[
              "custom:portal_role"
            ];
            // If Cognito User "custom:portal_role" is undefined, set PORTAL_CONST.AGENT_ROLE as default.
            const authorized = isAuthorized(userRoles || PORTAL_CONST.AGENT_ROLE, associatedPortalRole);
            if (authorized && validateHostURL(associatedPortalRole)) {
              resolve({ session, currentUser });
            } else {
              localStorage.removeItem(
                PORTAL_CONST.LOCAL_STORAGE_ASSOCIATED_ROLE
              );
              reject("Session error. Reject: " + err);
            }
          }
        });
      }
    });
  };

  const setupUserInfo = async (currentUser) => {
    try {
      const userInfo = await updateUserAttributes(currentUser);
      setUserData(userInfo);
    } catch (e) {
      console.error(e);
    }
  };
  const sendForgotPasswordCode = async (email, setStep) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username: email, Pool });

      cognitoUser.forgotPassword({
        onSuccess: function () {
          resolve();
        },
        onFailure: function (err) {
          console.error(err);
          reject(err);
        },
        inputVerificationCode: function () {
          setStep(2);
        },
      });
    });
  };

  const confirmNewPassword = async (email, verificationCode, newPassword) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username: email, Pool });
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: function () {
          resolve();
        },
        onFailure: function (err) {
          console.error(err);
          reject(err);
        },
      });
    });
  };

  const updateUserAttributes = async (currentUser) => {
    return await new Promise((resolve, reject) => {
      currentUser.getUserAttributes(function (err, userAttrs) {
        if (err) {
          reject(err.message);
        }
        const userInfo = {
          token: currentUser.signInUserSession.idToken.jwtToken,
          email: "",
          name: "",
          family_name: "",
          phone_number: "",
          custom_sales_id: "",
          custom_access_flag: "",
          custom_iso_id: "custom:iso_id",
          custom_portal_role: "custom:portal_role",
          custom_schedule_a_std_id: "custom:schedulea_std_id",
          custom_staff_id: "custom:staff_id",
          is_portal_admin: "custom:is_portal_admin",
        };
        userAttrs?.forEach(function (attribute) {
          if (attribute.getName() === "email") userInfo.email = attribute.Value;
          if (attribute.getName() === "name") userInfo.name = attribute.Value;
          if (attribute.getName() === "family_name")
            userInfo.family_name = attribute.Value;
          if (attribute.getName() === "phone_number")
            userInfo.phone_number = attribute.Value;
          if (attribute.getName() === "custom:sales_id")
            userInfo.custom_sales_id = attribute.Value;
          if (attribute.getName() === "custom:access_flag")
            userInfo.custom_access_flag = attribute.Value;
          if (attribute.getName() === "custom:iso_id")
            userInfo.custom_iso_id = attribute.Value;
          if (attribute.getName() === "custom:portal_role")
            userInfo.custom_portal_role = attribute.Value;
          if (attribute.getName() === "custom:schedulea_std_id")
            userInfo.custom_schedule_a_std_id = attribute.Value;
          if (attribute.getName() === "custom:staff_id")
            userInfo.custom_staff_id = attribute.Value;
          if (attribute.getName() === "custom:is_portal_admin")
            userInfo.is_portal_admin = attribute.Value;
        });
        if (sessionStorage.getItem("impersonate")) {
          userInfo["_impersonate-email"] =
            sessionStorage.getItem("impersonate");
        }
        resolve(userInfo);
      });
    });
  };

  const signIn = async (email, password, associatedPortalRole) => {
    setIsSigning(true);
    return await new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (cognitoUserSession) => {
          const userRoles = cognitoUserSession.getIdToken().decodePayload()[
            "custom:portal_role"
          ];
          const authorized = isAuthorized(userRoles || PORTAL_CONST.AGENT_ROLE, associatedPortalRole);
          if (authorized) {
            setIsSigning(false);
            sessionStorage.removeItem("impersonate");
            localStorage.setItem(
              PORTAL_CONST.LOCAL_STORAGE_ASSOCIATED_ROLE,
              associatedPortalRole
            );
            resolve({ cognitoUserSession, cognitoUser });
          } else {
            setIsSigning(false);
            logOut();
            reject({ message: "Unauthorized access (user role)." });
          }
        },
        onFailure: (err) => {
          setIsSigning(false);
          console.error(err);
          reject(err);
        },
        newPasswordRequired: () => {
          setIsSigning(false);
          reject("newPasswordRequired");
        },
      });
    });
  };

  const logOut = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      localStorage.removeItem(PORTAL_CONST.LOCAL_STORAGE_ASSOCIATED_ROLE);
      if (user) {
        sessionStorage.removeItem("impersonate");
        user.signOut();
        resolve();
      } else {
        reject("User not found");
      }
    });
  };
  const signUp = () => {
    console.log("Sign up");
  };

  const validateHostURL = (associatedPortalRole) => {
    const { host } = window.location;
    if (associatedPortalRole === "agent") {
      return !![
        PORTAL_CONST.LOCATION_HOST_ROLE_AGENT_LOCAL,
        PORTAL_CONST.LOCATION_HOST_ROLE_AGENT_DEV,
        PORTAL_CONST.LOCATION_HOST_ROLE_AGENT_STG,
        PORTAL_CONST.LOCATION_HOST_ROLE_AGENT_PRD,
      ].find((url) => String(url) === String(host));
    } else if (associatedPortalRole === "merchant") {
      return !![
        PORTAL_CONST.LOCATION_HOST_ROLE_MERCHANT_LOCAL,
        PORTAL_CONST.LOCATION_HOST_ROLE_MERCHANT_DEV,
        PORTAL_CONST.LOCATION_HOST_ROLE_MERCHANT_STG,
        PORTAL_CONST.LOCATION_HOST_ROLE_MERCHANT_PRD,
      ].find((url) => String(url) === String(host));
    }
    return false;
  };

  const isAuthorized = (userRoles, associatedPortalRole) => {
    return !associatedPortalRole
      ? false
      : !!userRoles
          ?.split(",")
          ?.find((userRole) => userRole.trim() === associatedPortalRole);
  };

  return {
    signIn,
    logOut,
    signUp,
    userSession,
    updateUserAttributes,
    setupUserInfo,
    isAuthenticated,
    isSigning,
    setIsAuthenticated,
    isChecking,
    setIsChecking,
    userData,
    setUserData,
    sendForgotPasswordCode,
    confirmNewPassword,
    validateHostURL,
  };
};
export default useAuth;

import React from "react";

const PrivateTemplate = ({ children, ...props }) => {
  return (
    <div {...props} className="privateTemplate">
      {children}
    </div>
  );
};

export default React.memo(PrivateTemplate);

const NmaButton = ({
  children,
  disabled = false,
  onClick,
  title = null,
  style,
  fullWidth = false,
  type = "button",
  uppercase = true,
  className = "",
}) => {
  if (!children) return;

  const classStyle = () => {
    switch (style) {
      case "gradientOutline":
        return "ui-nma-button_gradientOutline";
      case "filled":
        return "ui-nma-button_filled";
      default:
        return "ui-nma-button_default";
    }
  };

  return (
    <>
      <button
        type={type}
        className={`ui-nma-button ${classStyle()} ${
          fullWidth ? "ui-nma-button--full" : ""
        } ${className}`}
        style={{ textTransform: uppercase ? "uppercase" : "none" }}
        aria-label="Button"
        disabled={disabled}
        onClick={onClick}
        title={title}
      >
        {style === "gradientOutline" ? <span>{children}</span> : children}
      </button>
    </>
  );
};
export default NmaButton;

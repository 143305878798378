export const getEnv = () => {
  const { host } = window.location;
  if (
    host.includes("portal-development.metaltoad-sites.com") ||
    host.includes("localhost:")
  ) {
    return "development";
  } else if (host.includes("portal-staging.metaltoad-sites.com")) {
    return "staging";
  } else {
    return "production";
  }
};

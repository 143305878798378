import {PORTAL_CONST} from "components/utils/collections/PortalConstants";
import useAuth from "hooks/useAuth";

const useAssociateRole = () => {
  const { logOut, validateHostURL } = useAuth();

  const getRole = () => {
    const locationHost = window.location.host;
    const storedRole =
      localStorage.getItem(PORTAL_CONST.LOCAL_STORAGE_ASSOCIATED_ROLE) || null;
    let selectedRole = String(PORTAL_CONST.AGENT_ROLE);

    const isMerchantURL = [
      PORTAL_CONST.LOCATION_HOST_ROLE_MERCHANT_LOCAL,
      PORTAL_CONST.LOCATION_HOST_ROLE_MERCHANT_DEV,
      PORTAL_CONST.LOCATION_HOST_ROLE_MERCHANT_STG,
      PORTAL_CONST.LOCATION_HOST_ROLE_MERCHANT_PRD,
    ].find((url) => String(url) === String(locationHost));
    if (isMerchantURL) selectedRole = "merchant";

    const definedRole = storedRole || selectedRole;
    let result;
    if (definedRole !== "agent" && definedRole !== "merchant") {
      result = null;
    } else {
      result = definedRole;
    }

    return result
  };

  const validateAssociatedRole = (app_portalRole) => {
    const localStoredRole = localStorage.getItem(
      PORTAL_CONST.LOCAL_STORAGE_ASSOCIATED_ROLE
    );
    if (
      localStoredRole === "" ||
      (localStoredRole !== "agent" &&
        localStoredRole !== "merchant" &&
        localStoredRole !== null) ||
      (app_portalRole !== localStoredRole && localStoredRole !== null) ||
      !validateHostURL(app_portalRole)
    ) {
      logOut().then(() => {
        window.location.href = "/";
      });
    }
  };

  return {
    getRole,
    validateAssociatedRole,
  };
};
export default useAssociateRole;

import {CognitoUserPool} from "amazon-cognito-identity-js";
import {getEnv} from "components/utils/getEnv";

const getPoolData = () => {
  const env = getEnv();

  // Default: Prod credentials
  const pool = {
    UserPoolId: "us-west-2_ssAgMANnO",
    ClientId: "68am79tkkbkek2jj8ghihko28q",
  };

  if (env === "development") {
    pool.UserPoolId = "us-west-2_r16P73V2x";
    pool.ClientId = "sinaabjsprj86k6b9m5n48105";
  } else if (env === "staging") {
    pool.UserPoolId = "us-west-2_6GTWs39QX";
    pool.ClientId = "3ge60bc2b4ens18l02iqcp9tfl";
  }
  return {
    ...pool,
  };
};

const poolData = getPoolData();

const UserPool = new CognitoUserPool(poolData);

export default UserPool;

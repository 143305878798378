import {useContext, useEffect, useState} from "react";
import {AppContext} from "contexts/AppContext";

const usePortalTemplate = (routes) => {
    const [currentRoute, setCurrentRoute] = useState(null);

    const {
        appContextValues: { location, showMobileMenu, isSm },
        setAppContextValues,
    } = useContext(AppContext);

    useEffect(() => {
        if (routes) {
            routes.find((route) => {
                if (route.path === location.pathname) {
                    setCurrentRoute({
                        title: route.title,
                        path: route.path,
                    });
                    return true;
                } else {
                    const matches = route.routes?.filter((innerRoute) => {
                        if (
                            location.pathname === innerRoute.path ||
                            isMatch(innerRoute.path, location.pathname)
                        ) {
                            return true;
                        }
                    });
                    if (matches?.length) {
                        setCurrentRoute({
                            title: matches[0].title,
                            path: matches[0].path,
                        });
                    }
                }
                return false;
            });
        }
    }, [location, routes]);

    const hideMobileMenu = () => {
        setAppContextValues((prev) => {
            return {
                ...prev,
                showMobileMenu: false,
            };
        });
    };

    const isMatch = (string1, string2) => {
        const regexPattern = new RegExp(string1.replace(/:[a-zA-Z]+/g, "[^/]+"));
        return regexPattern.test(string2);
    };

    return {
        currentRoute,
        setCurrentRoute,
        location, showMobileMenu, isSm,
        setAppContextValues,
        hideMobileMenu,
        isMatch
    }
}

export default usePortalTemplate
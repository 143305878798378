import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import NmaButton from "components/molecules/NmaButton";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const MuiDialogTitleStyled = styled(DialogTitle)(() => ({
  "&.MuiDialogTitle-root": {
    color: "#534FA1",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "133.4%",
    padding: "38px 48px 18px",
  },
  ".MuiSvgIcon-root": {
    fontSize: "48px",
  },
}));

const MuiFormControlStyled = styled(FormControl)(() => ({
  "&.MuiFormControl-root": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "16px",
    ".MuiInputBase-root": {
      width: "100%",
    },
    ".MuiTextField-root": {
      width: "calc(50% - 8px)",
    },
  },
}));

const MuiDialogStyled = styled(Dialog)(() => ({
  "&.MuiDialog-root": {
    ".MuiPaper-root": {
      width: "680px",
      maxWidth: "680px",
      borderRadius: "16px",
      marginTop: "0",
    },
    ".MuiDialogContent-root": {
      padding: "0 48px 16px",
    },
  },
}));

const RequestAccessDialog = ({ isOpen, handleClose, handleConfirm }) => {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <div className="ui-request-access-dialog">
      <MuiDialogStyled open={isOpen} onClose={handleClose}>
        {!isSuccess && (
          <>
            <MuiDialogTitleStyled>Request Access Form</MuiDialogTitleStyled>
            <DialogContent>
              <MuiFormControlStyled fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={10}
                  onChange={() => {
                    console.log("change");
                  }}
                  sx={{
                    "&.MuiInputBase-root": {
                      color: "rgba(0, 0, 0, 0.6)",
                    },
                  }}
                >
                  <MenuItem value={10}>I am...</MenuItem>
                </Select>
                <TextField
                  id="outlined-basic"
                  label="First Name*"
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  label="Last Name*"
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  label="Email*"
                  variant="outlined"
                />
                <TextField
                  id="outlined-basic"
                  label="Phone*"
                  variant="outlined"
                />
              </MuiFormControlStyled>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                padding: "8px 48px 32px",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <NmaButton
                onClick={() => {
                  handleConfirm();
                  setIsSuccess(true);
                }}
                style="filled"
                fullWidth={true}
              >
                Submit
              </NmaButton>
              <NmaButton
                onClick={() => {
                  handleClose();
                  setIsSuccess(false);
                }}
              >
                Cancel
              </NmaButton>
            </DialogActions>
          </>
        )}
        {isSuccess && (
          <>
            <div className="ui-dialog-messages">
              <img width="80" height="80" src="/CheckCircleTwoTone.svg" />
              <h3>Request Submitted</h3>
              <p>
                Your request was successfully submitted and will be evaluated by
                our team.
              </p>
            </div>
            <DialogActions
              sx={{
                display: "flex",
                padding: "8px 48px 32px",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <NmaButton
                onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    setIsSuccess(false);
                  }, 500);
                }}
                style="filled"
                fullWidth={true}
              >
                CLOSE
              </NmaButton>
              <NmaButton
                onClick={() => {
                  handleClose();
                  setIsSuccess(false);
                }}
              >
                submit another application
              </NmaButton>
            </DialogActions>
          </>
        )}
      </MuiDialogStyled>
    </div>
  );
};

export default RequestAccessDialog;
